import React, { FC } from 'react'
import LoginTemplate from '../../components/Login/LoginTemplate'
import Register from '../../components/Login/Register'

const RegisterPage: FC = () => {
    return (
        <div className='register-page'>
            <LoginTemplate child={<Register />} />
        </div>
    )
}

export default RegisterPage
