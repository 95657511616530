import React, { FC } from 'react'
import LoginTemplate from '../../components/Login/LoginTemplate'
import ForgotPassword from '../../components/Login/ForgotPassword'

const ForgotPasswordPage: FC = () => {
    return (
        <div className='forgot-password-page'>
            <LoginTemplate child={<ForgotPassword />} />
        </div>
    )
}

export default ForgotPasswordPage
