import React, { FC, ReactNode, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Locale from '../Locale'
import useAuth from '../../hooks/useAuth'

const LoginTemplate: FC<{ child: ReactNode }> = ({ child }) => {
    // Global
    const navigate = useNavigate()
    const { auth } = useAuth()

    useEffect(() => {
        // Redirect if connected
        if (auth?.username) {
            if (auth.roles.includes('ROLE_PARENT')) {
                navigate('/activity')
            } else {
                navigate('/')
            }
        }
    }, [])

    return (
        <div className='login-template d-flex min-vh-100'>
            <Locale />
            <div className='container d-flex col-12 justify-content-center align-items-center flex-column'>
                <span className='text-center'>
                    <img className='logo w-50' alt='Logo' src='/images/logo.png'></img>
                </span>
                {child}
            </div>
        </div>
    )
}

export default LoginTemplate
