import React, { FC, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { useCookies } from 'react-cookie'
import useAuth from '../../hooks/useAuth'
import useLoader from '../../hooks/useLoader'
import useMessage from '../../hooks/useMessage'
import InputField from '../InputField'
import { startAuthentication } from '@simplewebauthn/browser'
import { createWebAuthnRequestCall, createWebAuthnRequestOptionCall, getTokenSocialRedirectCall, createTokenCall } from '../../api/token'
import { loginUser } from '../../helperFunctions/loginUser'

const Login: FC = () => {
    // Global
    const navigate = useNavigate()
    const { setAuth } = useAuth()
    const { setLoader } = useLoader()
    const { setType, setMessage, setShow } = useMessage()
    const [, setCookie] = useCookies()
    const { t }: { t: TFunction } = useTranslation('login')
    const { t: tc }: { t: TFunction } = useTranslation('common')
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()

    // Form refs
    const { ref: refUsername, ...inputPropsUsername } = register('username', {
        required: tc('form-error.required', { field: t('login-email-page.form.email.label') }),
    })
    const { ref: refPassword, ...inputPropsPassword } = register('password', {
        required: tc('form-error.required', { field: t('login-email-page.form.password.label') }),
    })

    // Call Login Submit
    const loginSubmit: SubmitHandler<FieldValues> = async (data) => {
        setLoader(1)

        try {
            const response = await createTokenCall(data.username, data.password)
            loginUser(response.token, response.refresh_token, setAuth, setCookie, navigate)
        } catch {
            setMessage(t('login-email-page.form.error.invalid-credential.message'))
            setType('error')
            setShow(true)
            navigate('/login')
        }

        setLoader(0)
    }

    // Redirect URL to social Login
    const loginSocialClick = async (social: string) => {
        setLoader(1)

        try {
            const response = await getTokenSocialRedirectCall(social, 'login')
            window.location.href = response.redirect_url
        } catch {
            navigate('/500')
        }

        setLoader(0)
    }

    // Register Biometric Click
    const loginBiometricClick = async () => {
        try {
            const response = await createWebAuthnRequestOptionCall()
            const biometric = await startAuthentication(response.options)

            const responseToken = await createWebAuthnRequestCall(biometric, response.options)
            loginUser(responseToken.token, responseToken.refresh_token, setAuth, setCookie, navigate)
        } catch (e: any) {
            if (e.name !== 'NotAllowedError') {
                setMessage(t('login-page.biometric.error.message'))
                setType('error')
            }
            setShow(true)
        }
    }

    useEffect(() => {
        if (localStorage.getItem('biometric') === 'true') {
            loginBiometricClick()
        }
    }, [])

    return (
        <div className='login-template-form p-4 d-flex justify-content-center align-items-center flex-column'>
            <h1 className='login-template-subtext text-center fw-bold text-uppercase pb-3'>{t('login-email-page.h1')}</h1>
            <form className='d-flex flex-column w-100' onSubmit={handleSubmit(loginSubmit)}>
                <InputField
                    label={t('login-email-page.form.email.label')}
                    type='text'
                    className='login-template-field'
                    error={!!errors.username}
                    helper={errors?.username?.message?.toString()}
                    inputRef={refUsername}
                    inputProps={inputPropsUsername}
                ></InputField>
                <InputField
                    label={t('login-email-page.form.password.label')}
                    type='password'
                    className='login-template-field'
                    error={!!errors.password}
                    helper={errors?.password?.message?.toString()}
                    inputRef={refPassword}
                    inputProps={inputPropsPassword}
                ></InputField>
                {localStorage.getItem('biometric') === 'true' && (
                    <button className='login-template-submit' type='button' onClick={() => loginBiometricClick()}>
                        {t('login-page.form.biometric.label')}
                    </button>
                )}
                <button className='login-template-submit' type='submit'>
                    {t('login-email-page.form.submit.label')}
                </button>
            </form>
            <div className='text-center py-2'>
                <Link to='/forgot-password'>{t('login-email-page.forgot-password.link')}</Link>
            </div>
            <div className='text-center pt-2'>
                <button className='login-template-submit google' onClick={() => loginSocialClick('google')}>
                    <span>
                        <i className='fa-brands fa-google'></i>
                    </span>
                </button>
                <button className='login-template-submit facebook' onClick={() => loginSocialClick('facebook')}>
                    <span>
                        <i className='fa-brands fa-facebook'></i>
                    </span>
                </button>
                <button className='login-template-submit apple' onClick={() => loginSocialClick('apple')}>
                    <span>
                        <i className='fa-brands fa-apple'></i>
                    </span>
                </button>
            </div>
            <hr className='w-100' />
            <span className='pt-3'>
                {t('login-page.register.label')}
                <Link to='/register' className='text-decoration-underline'>
                    {t('login-page.register.link')}
                </Link>
            </span>
        </div>
    )
}

export default Login
