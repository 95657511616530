import React, { FC } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Button, DialogActions, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import useModal from '../hooks/useModal'

const Modal: FC = () => {
    // Global
    const { title, content, show, setShow, buttons } = useModal()

    // Close Modal
    const handleClose = () => {
        setShow(false)
    }

    return (
        <>
            <Dialog
                open={show}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                fullWidth={true}
                maxWidth={false}
                sx={{
                    '.MuiDialog-paperFullWidth': { margin: '10px', maxHeight: '100%', maxWidth: '100%', width: '100%' },
                    '.MuiDialogContent-root': { padding: '10px' },
                }}
            >
                {title !== null && <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>}
                <IconButton
                    aria-label='close'
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>{content}</DialogContent>
                {buttons !== null && (
                    <DialogActions>
                        {buttons.map((button, index) => (
                            <Button key={index} color={button.color || 'primary'} onClick={button.onClick}>
                                {button.label}
                            </Button>
                        ))}
                    </DialogActions>
                )}
            </Dialog>
        </>
    )
}

export default Modal
