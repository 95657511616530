import React, { FC } from 'react'
import LoginTemplate from '../../components/Login/LoginTemplate'
import ResetPassword from '../../components/Login/ResetPassword'

const ResetPasswordPage: FC = () => {
    return (
        <div className='reset-password-page'>
            <LoginTemplate child={<ResetPassword />} />
        </div>
    )
}

export default ResetPasswordPage
