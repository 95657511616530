import React, { FC } from 'react'
import LoginTemplate from '../../components/Login/LoginTemplate'
import Login from '../../components/Login/Login'

const LoginPage: FC = () => {
    return (
        <div className='login-page'>
            <LoginTemplate child={<Login />} />
        </div>
    )
}

export default LoginPage
